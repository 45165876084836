* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;

}

.home,
.about,
.projects,
.contact {
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about {
  color: #ffffff;
}

.projects {
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #2f2f2f;
}

::-webkit-scrollbar-thumb {
  background-color: #000000c7;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2f2f2f;
}

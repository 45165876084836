.cards {
  /* padding: 4rem; */
  /* background: #00000097; */
}

.cards__hearder {
  color: #ffffff;
}

.cards__container {
  display: flex;
  flex-flow: column;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* display: block; */
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: all 0.2s linear;
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item__img {
    width: 55%;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

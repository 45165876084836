.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  padding-bottom: 50px;
  border-radius: 25px;
  width: 40rem;
  margin: auto;
  padding: 15px;
  flex-wrap: wrap;
  margin-top: 50px;
  box-shadow: 0 6px 20px rgba(255, 255, 255, 0.454);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  background-color: #ffffffad;
}

h2 {
  text-align: center;
  padding-top: 10px;
}

#contact {
  padding: 4rem;
  background: #00000097;
}

.contact-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  justify-content: space-between;
  padding: 15px;
}

.contact-form-button {
  width: 100px;
  display: flex;
  justify-content: center;
  margin: 5px;
  font-size: 16px;

}

.contact-field {
  width: 35rem;
  height: 30px;
  margin-bottom: 5px;
  font-size: 16px;
}

.contactMessage {
  width: 35rem;
  height: 160px;
  margin-bottom: 5px;
  font-size: 16px;
}

/* @media screen and (max-width: 768px) {
  .contact-form, 
  .contact-input {
    width: 100px;
  }
  .contact-field,
  .contactMessage {
    width: 100px;
  }
} */

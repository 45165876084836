.about {
  padding: 4rem;
}

#about {
  padding: 4rem;
  background: #00000097;
}

h1,
h2 {
  text-align: center;
  color: #ffffff;
}

.about__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.about__item {
  display: flex;
  align-items: center;
  flex: 1;
  margin-top :-50px;
}

.about__item__link {
  display: flex;
  flex-flow: column;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.about__item__img {
  display: flex;
  width: 80%;
  max-width: 100%;
  height: 80%;
  max-height: 100%;
  object-fit: contain;
  transition: all 0.2s linear;
}

.about__item__img:hover {
  transform: scale(1.1);
}

.about__item__info {
  padding: 20px 30px 30px;
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(255, 255, 255, 0.454);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  background-color: #ffffffad;
}

.about__item__text {
  color: #000000;
  font-size: 18px;
  line-height: 24px;
  
}

.about__item__header {
  padding-bottom: 10px;
}

.about-text {
  width: 50vw;
  padding-right: 30px;
}

@media only screen and (min-width: 1024px) {
  .about__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .about__item {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 1024px) {
  .about__item__img,
  .about__item__pic-wrap {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    max-width: 100%;
    height: 90%;
    max-height: 100%;
  }
}

@media only screen and (max-width: 1180px) {
  .about-text, .about__item__header {
    width: 50vw;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1024px) {
  .about__item__text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1024px) {
  .about__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    /* width: 90%; */
    /* margin: 0 auto; */
  }
}
